* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none !important;
}
li {
	list-style: none;
}
.all_center {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

#card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
	max-height: 355px;
}

#card {
  width: calc(33.3% - 22px);
  margin: 10px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.css-overlay {
	top: 0;
	left: 0;
  z-index: 99;
  cursor: wait;
	width: 100vw;
	height: 100vh;
	position: absolute;
  background-color: rgba(255, 255, 255, 0.4) !important;
}